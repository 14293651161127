<template>
  <div>
    <p>
      You LEGO Universe fans are kicking up a cloud of creative inspiration by overcoming the Creation Lab’s special ‘Make a monument!’ challenge!
    </p>
    <p>
      From the pool of your creations, the LEGO Universe development team carefully selected these celebrations of imagination before choosing a single inspiring idea to include in LEGO Universe when it launches!
    </p>
    <p>
      LEGO Universe art director, Phillip Atencio, wrote the following words about the team’s top selections:
    </p>
    <p>
      <strong>1st place monument!</strong>
    </p>
    <!-- picF82AD7FD0AFAE5A2300F2FA1353E1C0A.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/monument-1.jpg">
    <p>
      <strong><router-link :to="{ name: 'creation-lab-creation', params: {id: 'dc5e1027-4bd6-4bf4-94d4-de857725d443'} }">Boundless Possibilities</router-link>, by turtlefan014</strong>
      <br><em>This static model conveys a sense of movement that resonates closely to what I feel imagination would look like if visualized. There is just enough form to physically represent imagination and just enough omitted to let viewers' minds create the rest!</em>
    </p>
    <p>
      LEGO FAN-tastic creation Kickflip645! Your monument idea is going to look great in the launch of LEGO Universe! Congratulations also to these other finalists and to the creative LEGO builders who made honorable mention!
    </p>
    <p>
      <strong>Finalists:</strong>
    </p>
    <!-- pic342A418C27D871F38E99A6E00C4D0FEC.jpg -->
    <img class="rounded h-265" src="@/assets/news-network/monument-2.jpg">
    <p>
      <strong><router-link :to="{ name: 'creation-lab-creation', params: {id: 'd0367d32-3630-4c1a-a5a7-5781b38f67d3'} }">Color Brick Fountain</router-link>, by kickflip645</strong>
      <br><em>This is a great model. The colors used have a classic feel to them and the arrangement is free and organic. These forms contrast nicely with the organized neutral bricks that form the base of the model. Excellent!</em>
    </p>

    <!-- picF7AFCA519E44025816697BCE5FFD3420.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/monument-3.jpg">
    <p>
      <strong><router-link :to="{ name: 'creation-lab-creation', params: {id: '12e3607d-5967-429e-98e8-ab39220c8f4f'} }">DNA Workers</router-link>, by big000</strong>
      <br><em>Nucleo-Cool! A building block represented by LEGO building blocks. Simple and to the point.</em>
    </p>

    <!-- picC26C24E3986D6164A95071E48FAB71CD.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/monument-4.jpg">
    <p>
      <strong><router-link :to="{ name: 'creation-lab-creation', params: {id: '5da71b14-232f-424e-a538-9b25e2c1c4f2'} }">Ideen-Wirbel</router-link>, by Jens8844</strong>
      <br><em>It takes imagination and technical skill to make elements that seem to work best when aligned at right angles feel organic and lively. This model feels like a big celebration.</em>
    </p>

    <!-- pic112A57D84FBCCCBF5340690A35D25E1B.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/monument-5.jpg">
    <p>
      <strong><router-link :to="{ name: 'creation-lab-creation', params: {id: '548c43e8-c919-45ee-956c-6ebcb2a3f696'} }">The Creation</router-link>, by Drowme</strong>
      <br><em>This model represents the fundamental art of building. This is a representation of a basic iconic element. This basic element can be used to create the most amazing models. All you need is your imagination.</em>
    </p>
    <p>
      <strong>Honorable mentions:</strong>
      <br>
      <router-link :to="{ name: 'creation-lab-creation', params: {id: 'f7179405-b4b0-4b4a-ba36-0c0f81f6bcea'} }">
        <strong>flipz</strong>
      </router-link>
      <br>
      <router-link :to="{ name: 'creation-lab-creation', params: {id: '67189ad2-4702-49ea-8bc5-be548f3c94c5'} }">
        <strong>moo0328</strong>
      </router-link>
      <br>
      <router-link :to="{ name: 'creation-lab-creation', params: {id: 'd90f501d-7c8a-449b-9d51-d2362802bf79'} }">
        <strong>diggermad</strong>
      </router-link>
      <br>
      <router-link :to="{ name: 'creation-lab-creation', params: {id: 'f39a632b-0c24-486f-938d-e0952c46f9e0'} }">
        <strong>prosuader2000</strong>
      </router-link>
      <br>
      <router-link :to="{ name: 'creation-lab-creation', params: {id: '5955ec97-faf2-49c1-a94e-801a5c547ce3'} }">
        <strong>cosmicace</strong>
      </router-link>
    </p>
    <p>
      Great job, everyone!
    </p>
    <p>
      Bolt over to the Creation Lab to overcome a fun creative challenge today! Your inspiring ideas help Professor Brickkeeper power up his battle against the forces of chaos in LEGO Universe!
    </p>
  </div>
</template>
